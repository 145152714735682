.esconder {
  display: none;
}

.clearfix {
  clear: both;
}

.box {
  color: #333;
  border: 1px solid #ddd;
}
.title-box {
  cursor: pointer;
  padding: 15px;
  background-color: #f5f5f5;
}
.title {
  width: calc(100% - 20px);
  text-align: center;
}
.icon {
  width: 20px;
  display: flex;
  color: #666;
  float: right;
}
.icon .fa {
  font-size: 20px;
  transition: all 0.1s;
  transform: rotate(90deg);
}
.icon .fa.down {
  transform: rotate(0deg);
}

.content {
  padding: 15px;
  border-top: 1px solid #ddd;
}

.captionFigura{
  text-align: center;
  padding-bottom: 5%;
}

.figura{
  padding-top: 5%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.procedimentos{
  background-color: #ff4b4b;
}

.clinico{
  margin: auto;
  background-color: #bcffad;
  height: 100%;
  width: 100%;
  padding-top: 1.5%;
  padding-left: 1%;
}

.trauma{
  margin: auto;
  background-color: #ffe6ed;
  height: 100%;
  width: 100%;
  padding-top: 1.5%;
  padding-left: 1%;
}

.avaliacao{
  margin: auto;
  background-color: #a5c6ff;
  height: 100%;
  width: 100%;
  padding-top: 1.5%;
  padding-left: 1%;
}
.menu2{
  
  margin: auto;
  background-color: #1d4180;
  color: #ffffff
}


.outros{
  margin: auto;
  background-color: #e3e3e3;
  height: 100%;
  width: 100%;
  padding-top: 1.5%;
  padding-left: 1%;
}

.IonCardContent{
  text-align: justify;
  text-justify: inter-word;
}
